import * as React from "react"
import {Link} from "gatsby";
import * as styles from "./pages.module.css"


const pageLinks = [
//    {
//        text: "Programm",
//        url: "/program",
//    },
//    {
//        text: "Geschenke",
//        url: "/gifts",
//    },
//    {
//        text: "Menü",
//        url: "/menu",
//    },
//    {
//        text: "Party",
//        url: "/party",
//    },
]

const Pages = () => (
<div className={styles.pages}>
    {pageLinks.map((link, i) => (
        <React.Fragment key={link.url}>
            <Link className={styles.page} activeClassName={styles.activePage} to={link.url}>{link.text}</Link>
            {i !== pageLinks.length - 1 && <> · </>}
        </React.Fragment>
    ))}
</div>
)
export default Pages