import * as React from "react"
import {Link} from "gatsby"
import {StaticImage} from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import * as styles from "../components/index.module.css"
import Banner from "../components/banner";
import giphy from "../images/giphy.gif";

const IndexPage = () => (
  <Layout>
    <Seo title="Party" />
    <Banner illustration={
        <img height={100} src={giphy} alt="Otter dancing with a fish" />
    } title="Party" />
    <div>
        Klicke <a href="https://festify.us/party/-NBn2CHjPW2y9sU8noJH">
        hier
    </a> und füge deine Songs zur Partyplaylist hinzu


    </div>

  </Layout>
)

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="Party" />

export default IndexPage
