import * as React from "react"
import {Link} from "gatsby";
import * as styles from "./index.module.css"
import Pages from "./pages";


const Banner = ({ illustration, title }) => (
    <>
            <div className={styles.textCenter}>
                <Link to="/">
                    { illustration }
                </Link>
                <h1>
                    {  title }
                </h1>
                <Pages />
            </div>
    </>
)
export default Banner